import React from "react";
import { XMLPromise, XMLRequest } from "../../../utils/calls";
import { Form } from "../../form/form";
import {useDataContext} from "../../../providers/data/provider_data";
import {createFormObject} from "../../../utils/frmObject";
import { useThemeContext } from "../../../providers/theme/provider_theme";

export const ModalSkillsEditor=({modalstate})=>{
    const {theme}=useThemeContext();
    const {data,setData}=useDataContext();
    const handleSubmit=(e)=>{
        e.preventDefault();
        const skillsFrm=document.getElementById("skillsEdit");
        const skillsForm=new FormData(skillsFrm);
        const frmObj=createFormObject(skillsForm);
        frmObj.skillsId=data[0].skillsId;
        console.log(frmObj);
        // XMLRequest(frmObj,setData,"POST","/php/testing/skillsUpdate.php");
        XMLPromise(frmObj,setData,"POST","/php/index.php/skills/edit");
        modalstate();
    } 

    const handleCancel=(e)=>{
        e.preventDefault();
        modalstate();
    }
    // console.log(data);
    return(
        <Form 
        formClasses={`${theme}`}
        formInputs={[
            
            {default: data[0].skillsName, label: "Skill", name:"skill",type:"text", class:"standard"},
            {default: data[0].skillsType, label:"Skill Type",name:"type",type:"text",class:"standard"},
            {default: data[0].skillsLevel, label:"Skill Level",name:"level",type:"text",class:"standard"}
            ]} 
        formButtons={[
            {button:"Submit",function:handleSubmit},
            {button:"Cancel",function:handleCancel},
            // {button:"Testing",function:Testing}
            ]} 
            formName={"skillsEdit"}
    />
    )
}