import "./servicesPage.css";
import {images} from "../../images/images";
import { useEffect, useState } from "react";
import { XMLPromise } from "../../utils/calls";
import { arrayGroupBy } from "../../utils/array";

export const ServicesPage =(props)=>{
    const [servicesData,setServicesData] = useState();
    
    const initialData = async () => {
        XMLPromise("","","GET","/php/index.php/services/list/")
            .then(res=>{
                setServicesData(arrayGroupBy(res.data,"serviceType"))});
                
        console.log(servicesData);        
    }

    const handleOnScreen=(element, value)=>{
        value? element.classList.add("visible"): element.classList.remove("visible");
    };
    const onScroll=(e)=>{
        const els=document.querySelectorAll(".list-container");
        els.forEach(el=>{
            const elTop=el.getBoundingClientRect().top;
            const onScreen= elTop<window.innerHeight && elTop>0 || elTop<0  && el.getBoundingClientRect().bottom>0;       
            handleOnScreen(el,onScreen);
            
        })
    };

    useEffect(()=>{
        initialData();
        window.addEventListener('scroll', onScroll)
    },[])

    const serviceImgs = {
        "Management":images.office,
        "Process Automation":images.graph,
        "Workflow Tool Development":images.code
    }
    const Services =({header,servicesArr,img})=>{
        const [service, setService] = useState();
        
        const handleService=(e)=>{
            setService(e.target.id);
        };
        return (<article className="risen" style={{"--img":`url(${img})`}}>
                <h1>{header}</h1>
                    <div className="list-container">
                        <div className="service-description risen">
                            <p>{service}</p>
                        </div>
                        <ul>
                            {servicesArr.map((item)=>(
                                <li id={item.serviceDesc} onClick={handleService}>{item.service}</li> 
                            ))}

                        </ul>
                        
                    </div>
            
            </article>
        )
    }
    if(!servicesData){return<div>Loading...</div>}

    return (
        <main id="services-container">
            {Object.entries(servicesData).map(([service,index])=>{
                return <Services img={serviceImgs[service]} header={service} servicesArr={servicesData[service]}/>
            })}
        </main>
    )
}