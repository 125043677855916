import React from "react";
import { useParams } from "react-router-dom";


export const Text = () => {
  const {paramId} = useParams();
  return (
    <div id="text">
      <h1>{paramId}</h1>
    </div>
  );
};
