import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useThemeContext } from "../../providers/theme/provider_theme";
import { useModalContext } from "../../providers/modal/provider_modal";
// import { images } from "../../images/images";
import "./home.css";
import "../../data/menu";

export const HomePage = () => {
  
  const { theme, setTheme } = useThemeContext();
  return (
    <div className={`hero ${theme}`}>
      <div id="profile">
        <img
          src={require("../../images/profile.jpg")}
            // images.find((image) => image.Title === "Professional Profile").Link
          
          width="300px"
        ></img>
      </div>
      <div id="intro" className={`primary-txt ${theme}`}>
        <h1 id="site-name">Steven Sosebee</h1>
        <h3> Operations Manager</h3>
        <h3> Data Analyst</h3>
        <h3> Workflow Engineer</h3>
        <h3> Full Stack Developer</h3>
        <p>
        Operations Manager with over 15 years experience with a proven track record for delivering results and adding value in a fast-paced, high-stakes environment by managing resources effectively and seeking every opportunity for improvement.  Seeking new challenges and ability to gain more knowledge and skills.
        </p>
      </div>
    </div>
  );
};
