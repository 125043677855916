import React from "react";
import "./footer.css";
import { useThemeContext } from "../../providers/theme/provider_theme";

export const Footer = () => {
  const { theme, setTheme } = useThemeContext();
  return (
    <div className={`footer ${theme}`}>
      <p>Made by Steven Sosebee ©️ 2022</p>
      <ul>
        <li style={{"--name":"Twitter", "--socialColor":"#1DA1F2"}}>
          <a href="https://twitter.com/sosebee_dev" target="blank">
            <i className="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li style={{"--name":"Github", "--socialColor":"#6A6162"}}>
          <a href="https://www.github.com/steven-sosebee" target="blank">
            <i className="fa-brands fa-github"></i>
          </a>
        </li>
        <li style={{"--name":"Stack", "--socialColor":"#F48024"}}>
          <a href="https://stackoverflow.com/users/19899015/sosebee-dev" target="blank">
            <i className="fa-brands fa-stack-overflow"></i>
          </a>
        </li>
        <li style={{"--name":"LinkedIn", "--socialColor":"#4267B2", "--socialBG":"#FFFFFF"}}>
          <a href="https://www.linkedin.com/in/stevensosebee" target="blank">
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </li>
        <li style={{"--name":"Facebook","--socialColor":"#4267B2", "--socialBG":"#FFFFFF"}}>
          <a href="https://www.facebook.com/steven.sosebee" target="blank">
            <i className="fa-brands fa-facebook"></i>
          </a>
        </li>
        <li style={{"--name":"Instagram", "--socialColor":"#C13584"}}>
          <a href="https://www.instagram.com/sosebee_dev/" target="blank">
            <i className="fa-brands fa-instagram"></i>
          </a>
        </li>
        <li style={{"--name":"Reddit", "--socialColor":"#ff4500", "--socialBG":"#000000"}}>
          <a href="https://www.reddit.com/user/sosebee_dev" target="blank">
            <i className="fa-brands fa-reddit"></i>
          </a>
        </li>
        <li style={{"--name":"Email", "--socialColor":"#48886C", "--socialBG":"#000000"}}>
          <a href="mailto:steven@stevenwsosebee.com">
            <i className="fa-solid fa-envelope"></i>
          </a>
        </li>
      </ul>
      <br></br>
    </div>
  );
};
