// export const XMLRequest=(inputs, callback, callType, file)=>{
// 	// checks where the app is hosted and sets the file location accordingly.
// 	console.log(callType);
// 	// if (document.location.hostname==="localhost"){file=`http://localhost:8000${file}`};
// 	file=`${file}?id=${Math.random()}`;
// 	// Instantiating new XMLHttpRequest()
// 	const XMLReq = new XMLHttpRequest();
// 	XMLReq.open(callType,file, true);
	
// 	// If the call is sending data, set the data on the send method.
// 	if (callType==="POST"){
// 		// console.log(JSON.stringify(inputs));
// 		XMLReq.send(JSON.stringify(inputs));
// 	} else {
// 		XMLReq.send()};

// 	XMLReq.onload =function() {
// 		let res;
// 		if (this.status === 200) {
// 			try{
// 			res = JSON.parse(this.responseText);
// 			} catch(err){
// 				console.log(this.responseText);
// 				console.log(err);
// 			}
// 			// sends data back to parent component
// 			if (!callback || callback===""){
// 				console.log(this.responseText);
// 				return this.responseText;
// 			} else {
// 			callback.apply(null, [res])
// 			};
// 		}
// 		else {
// 			if (!callback || callback===""){
// 				console.log("something went wrong"); return "something went wrong..."
// 			} else {			
// 				callback.apply(null,[{error:"Something went wrong..."}])
// 			};
// 		}
// 	};
// };

export const XMLPromise = (data, callback, callType, file)=>{
	// console.log(file);
	file=`${file}?id=${Math.random()}`;
	return new Promise((resolve, reject)=>{
		const XMLReq = new XMLHttpRequest();
		XMLReq.open(callType,file,true);
		XMLReq.onload=function(){
			try{
				if (this.status===200){
					// console.log(JSON.parse(this.responseText));
					resolve(JSON.parse(this.responseText));
				};
			} catch(err) {
				console.log(this.responseText);
				reject(err);
			}
		};
		XMLReq.send((data==null)? null: JSON.stringify(data));
	})
}