import React, { useState, useContext } from "react";
import { useThemeContext } from "../../providers/theme/provider_theme";
import { useModalContext } from "../../providers/modal/provider_modal";
import { useUserContext } from "../../providers/user/provider_user";
import { Spinner } from "../loading/spinner";
export const ContentSection =({children})=>{
    const {theme}=useThemeContext();
    // const {user,loading}=useUserContext();
    
    // if (loading){return <Spinner/>}

    return (
        <div id="content" className={`margin-90 med ${theme}`}>
            {children}
        </div>
    )

}