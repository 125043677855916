import React, { useState } from "react";
import { useThemeContext } from "../../providers/theme/provider_theme";
import { useModalContext } from "../../providers/modal/provider_modal";
import { AddRecipe, ModalSplash,ModalEditor, ModalSkillsEditor, ModalLogin, ModalCreateAccount } from "./modal_types/";
import "./modal.css";
import { useUserContext } from "../../providers/user/provider_user";
import { Spinner } from "../loading/spinner";

export const Modal = () => {
  const {theme}=useThemeContext();
  // const {loading}=useUserContext();

  const { modal, setModal } = useModalContext();
  // let modalType = <></>;

  const handleModal = () => {
    // setVisible(!visible);
    setModal("closed");
  };

  if (modal==="closed" || !modal){return (<></>)}
  
  // if (modal === "recipe") {modalType = <AddRecipe />}

  // if (modal==="splash"){modalType = <ModalSplash modalstate={handleModal}/>}

  // if (modal==="jobEditor"){modalType = <ModalEditor modalstate={handleModal}/>}

  // if (modal ==="skillsEditor"){modalType = <ModalSkillsEditor modalstate={handleModal}/>}

  
  const modalType= {
    "waiting": <Spinner/>,
    "recipe":<AddRecipe />,
    "jobEditor":<ModalEditor modalstate={handleModal}/>,
    "skillsEditor":<ModalSkillsEditor modalstate={handleModal}/>,
    "login": <ModalLogin modalstate={handleModal}/>,
    "createAccount": <ModalCreateAccount modalstate={handleModal}/>
  }

  const selectModal=(modal)=>{
    return (!modal? modalType[`closed`]:modalType[modal]);
  }
// console.log(modal);

  return (
    <div className={`${theme} modal`}>
      <div className="pop-up-background">
        <div className="pop-up">
          {selectModal(modal)}
        </div>
      </div>
    </div>);
};
