import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useModalContext } from "../../providers/modal/provider_modal";
import { useDataContext } from "../../providers/data/provider_data";
import { XMLPromise, XMLRequest } from "../../utils/calls";
import { List } from "../../components/list/list";
import { arrayGroupBy, arraySortData } from "../../utils/array";
import { useParams } from "react-router-dom";
import "./resume.css";
import { dateMMMYYY } from "../../utils/formatting";
import { projects } from "../../data/projects";

export const StaticResume=()=>{
    // const [resumeData, setResumeData]=useState();
    // const [jobData,setJobData]=useState();
    // const [skillsData,setSkillsData] = useState();
    // const [projectData,setProjectData] = useState();
    // const [volunteerData,setVolunteerData] = useState();
    // const {resumeId}=useParams();
    
    // const initialDownload= async()=>{
    //     const data = await XMLPromise({resumeId},"","POST","/php/index.php/resume/listJobs/");
    //     setResumeData(data.data.resume[0]);
    //     setJobData(data.data.jobs);
    //     setProjectData(data.data.projects);
    // }

    const jobData = [
        {key:'analyst', jobsName:'Request Workflow Coordinator', jobsCompany: 'Eviden', jobsStart: '2021-08-01', jobsEnd: '2023-03-01', 
        accomplishments:[
            {accDesc: 'Implemented training for new hires to decrease training time'},
            {accDesc: 'Utilized Kaizen principles in daily work to provide continuous improvment for clients'},
            {accDesc: 'Supporting implementation of new request processes in ServiceNow by evaluating, reviewing, and developing workflows'},
            {accDesc:'Monitoring and reporting workflow statistics'},
            {accDesc:'Creating reporting in ServiceNow for workflow tracking and data visualization'},
            {accDesc: 'Meeting and exceeding metrics on service level agreements and performance metrics'}            
        ]},

        {key:'reporting_manager', jobsName:'Risk Management Reporting Manager', jobsCompany: 'Credit Suisse', jobsStart: '2017-11-01', jobsEnd: '2021-06-01', 
        accomplishments:[
            {accDesc: 'Automated work of 10 full-time employees performing manual risk reporting tasks'},
            {accDesc: 'Analyzed enterprise-level businesses and presented findings to senior leaders'},
            {accDesc: 'Authored/Reviewed Business Requirement Documents for complex regulatory updates to enterprise applications'},
            {accDesc: 'Created and presented materials to executives'},
            {accDesc: 'Created reporting tools using SQL and MS Access to allow non-technical users to access data'},
            {accDesc: 'Developed a process for analyzing risk in banking operations'},
            {accDesc: 'Developed reports illustrating risk and performance of region to global stakeholders'},
            {accDesc: 'Implemented standards for commentary, correspondence and documentation to ensure easy transfer of information and reporting'},
            {accDesc: 'Liaised with auditors and regulators to evidence controls and processes'},
            {accDesc: 'Managed personnel through consistent one-to-one coaching and annual objective setting'},
            {accDesc: 'Owned controls and processes required for SOX compliance'}
        ]},

        {key:'cash_manager', jobsName:'Cash Management and Reconciliation Manager', jobsCompany: 'Credit Suisse', jobsStart: '2015-09-01', jobsEnd: '2017-10-01', 
        accomplishments:[
            {accDesc: 'Managed departments of up to 20 staff'},
            {accDesc: 'Analyzed business metrics to gauge productivity and identify areas for improvement'},
            {accDesc: 'Authored Standard Operating Procedures (SOPs) and knowledge documents for regional and global audiences'},
            {accDesc: 'Authored/Reviewed Business Requirement Documents for complex regulatory updates to enterprise applications'},
            {accDesc: 'Created and presented materials to executives'},
            {accDesc: 'Developed and implemented controls and processes to improve client satisfaction and reduce cash settlement times by 1 week'},
            {accDesc: 'Developed and maintained multi-user databases'},
            {accDesc: 'Implemented standards for commentary, correspondence and documentation to ensure easy transfer of information and reporting'},
            {accDesc: 'Led weekly team meetings and managers meetings to review team status and progress on projects'},
            {accDesc: 'Managed personnel through consistent one-to-one coaching and annual objective setting'},
            {accDesc: 'Owned daily reconciliation and sign-off for cash accounts'},
            {accDesc: 'Reported status of regional KPI and KRI metrics to senior stakeholders'}
        ]},

        {key:'ops_manager', jobsName:'Operations and Controls Manager', jobsCompany: 'Credit Suisse', jobsStart: '2011-01-01', jobsEnd: '2015-08-01', 
        accomplishments:[
            {accDesc: 'Analyzed business metrics to gauge productivity and identify areas for improvement'},
            {accDesc: 'Analyzed enterprise-level businesses and presented findings to senior leaders'},
            {accDesc: 'Analyzed risk metrics for complex financial products and supporting operations'},
            {accDesc: 'Authored Standard Operating Procedures (SOPs) and knowledge documents for regional and global audiences'},
            {accDesc: 'Authored/Reviewed Business Requirement Documents for complex regulatory updates to enterprise applications'},
            {accDesc: 'Created and presented materials to executives'},
            {accDesc: 'Created workflows to support complex financial products'},
            {accDesc: 'Developed and maintained multi-user databases'},
            {accDesc: 'Developed desktop applications to improve efficiency and controls'},
            {accDesc: 'Functional subject matter expert for new application'},
            {accDesc: 'Implemented standards for commentary, correspondence and documentation to ensure easy transfer of information and reporting'},
            {accDesc: 'Managed offshore vendor teams and provided metrics on performance'},
            {accDesc: 'Managed personnel through consistent one-to-one coaching and annual objective setting'},
            {accDesc: 'Managed reviews of regulatory guidelines'},
            {accDesc: 'Programmed calculator to evaluate margin requirements at clearing houses.  New calculation resulted in $100,000,000 reduction in requirement'},
            {accDesc: 'Spearheaded project to eliminate dual keying of complex data leading to 90% reduction in missed collateral bookings'},
            {accDesc: 'Supported high-touch clients and fostered business relationships'},
            {accDesc: 'Implemented RAID reviews of department to understand current risks'},
            {accDesc: 'Led weekly team meetings and managers meetings to review team status and progress on projects'},
            {accDesc: 'Liaised with auditors and regulators to evidence controls and processes'},
            {accDesc: 'Managed reviews of regulatory guidelines'},
            {accDesc: 'Passed all internal and external audits without receiving any findings.'},
            {accDesc: 'Owned controls and processes required for SOX compliance'},
            {accDesc: 'Performed user-acceptance testing using ALM to document RAID items'},
            {accDesc: 'Reported status of regional KPI and KRI metrics to senior stakeholders'},
            {accDesc: 'Spearheaded regional review of industry guidelines and performed gap analysis on existing infrastructure'},
        ]},        

        {key:'ops_analyst', jobsName:'Operations Analyst', jobsCompany: 'Credit Suisse', jobsStart: '2007-01-01', jobsEnd: '2010-12-01', 
            accomplishments:[
        ]}
    ]

    const skillsData = {
        'Management and Client Success':[
            {skillsName:'Stakeholder and Service Level Management'},
            {skillsName: 'Team and Organizational Leadership'},
            {skillsName: 'Performance Metrics'},
            {skillsName: 'KPI/KRI Escalation Management'}
        ],

        'Operations and Reporting':[
            {skillsName: 'Data Analytics and Reporting'},
            {skillsName: 'Solution Development'},
            {skillsName: 'Process Design and Improvement'},
            {skillsName: 'Documentation'},
            {skillsName: 'Business Analysis'},
            {skillsName: 'Report Design'},
            {skillsName: 'Data Visualization'}
        ],

        'Delivering Solutions':[
            {skillsName: 'Application Development'},
            {skillsName: 'Workflow Design'},
            {skillsName: 'Web Development'},
            {skillsName: 'Full Stack Programming'},
            {skillsName: 'Project Leadership'}
        ],

        Technology:[
            {skillsName: 'MS Office (Word, Excel, Access, PowerPoint, SharePoint, etc.)'},
            {skillsName: 'Programming (VBA, Excel Macros)'},
            {skillsName: 'Database Design (SQL, no-SQL, Data Normalization)'},
            {skillsName: 'Web Development (JavaScript, HTML, CSS, PHP, MySQL, MongoDB)'}
        ]


    };

    const resumeData = [

    ];

    const projectData = [
        {
            projectTitle: 'Cleared Margin CCP Calculator Tool',
            projectDesc: 'Developed to calculate daily margin requirements for cleared derivative portfolio for large investment bank.  Utilized MS Access, SQL, VBA, and AS400 to extract positions and margin held to generate a regulatory requirement for collateral pledged.  Transformed data into a readable format and calculated margin requirements per regulations and policy.  Loaded data into target database for calculations and audit history.'
        },
        {
            projectTitle: 'Collateral Transaction Booking Tool',
            projectDesc: 'Created user interface to extract data from Oracle database and perform control reviews and output report for booking cash and security movements.  Utilized SQL, MS Access, and business algorithms to reduce cash breaks by 90%.  Created user interface and workflow with integrated controls enhancing the user experience.'
        },
        {
            projectTitle: 'Cash Settlement Workflow Tracking',
            projectDesc: 'Created workflow tool to automate client communication and issues tracking.  Utilized MS Access and VBA to reduce manual efforts by 1 FTE.'
        },

        {
            projectTitle: 'Leverage Finance Reporting Tool',
            projectDesc: 'Refactored existing report for weekly leverage finance positions for risk management review.  Utilized MS Access, VBA and business algorithms to calculate scenario risk values and reduce manual efforts by 20 hours each week.  Improved included streamlined extraction and data pipeline.  Front-to-back consistent transform algorithms and controls.  Loading data into reporting and target warehouse for historical data analysis.'
        }
    ];

    const accomplishments=(x)=>{
        return (
            <li 
                id={x.accId}
                className="effect-darken ">
            <span>{x.accDesc}</span>
        </li>)
    }
    const jobs=(x)=>{
        return ({
                element: <>
                    <p className="job-title">{x.jobsName} at {x.jobsCompany} - {dateMMMYYY(x.jobsStart,[5,7],[0,4])} to {dateMMMYYY(x.jobsEnd,[5,7],[0,4])}</p>
                    <p>{x.jobsDesc}</p> 
                    <ul className="accomplishments">{x.accomplishments.map(a=>(
                        accomplishments(a)))}    
                    </ul>
                    </>,
                key:x.jobsId,
                class:['standard jobs']
            })
    }
    
    const S=({list})=>{
        console.log("skills list...");
        return Object.entries(list).map(([skill,index])=>{
            return <div className="flex-1 border"><h5>{skill}</h5><ul className="no-padding">
                {list[skill].map(x=>{return <li>{x.skillsName}</li>})
        }</ul></div>})
    }
        
    if(!resumeData || !jobData || !skillsData || !projectData){
        return <div>Loading...</div>
    } else {
    return (
        <div id="resume">
            <div id="cover-letter">
                <section className="sticky">
                    <h2>Steven Sosebee</h2>
                    <div>
                        <img width={200} id={`cv-icon`} src={require("../../images/profile.jpg")}/>
                        <ul>
                            <li><p>steven@stevenwsosebee.com</p></li>
                            <li><p>910-850-5836</p></li>
                        </ul>
                        </div>
                </section>
                    <br/>
                        <ul>
                        <li>
                            <p className={"paragraph"}>
                                Professional with over 10 years experience in operations, management, and customer support.  
                                Proven track record for delivering results and adding value in complex and fast-paced environments through resourcefulness and collaboration.
                            </p>  
                            
                            <p className={"paragraph"}>
                                On the job experience in data analytics for large data sets.  
                                Capable in extracting complex data sets using SQL.  
                                Built MI dashboards, report suites and ad-hoc reports using various tools ranging from MS Excel and Access, to Qlik, Business Objects, ServiceNow.  
                                Followed common data normalization techniques and workflow to produce high-quality output to meet business needs.
                            </p>

                            <p className={"paragraph"}>
                                Self-taught programmer and application developer.  
                                Utilized multiple programming languages and tools to create business applications to enhance productivity.  
                                Project experience includes SQL and NoSQL database schemas, full-stack web development, VBA for MS Office products and deploying and maintaining multi-user applications.
                            </p>

                            <p>Managed several teams through structural change and championed process improvements to increase productivity.  Delivered on large-scale change to transform inefficient settlements teams into multiple workflows, improving controls and customer satisfaction.  Structured margin processes to be better allies to customer service representatives and reduce escalations.  Developed and received funding to implement process improvements to automate manual workflow driving down processing failures.</p>
                        </li>
                    </ul>
                
                
                <p>
                    
                </p>
            </div>
            <div id="cv">
            <div id="resume-title"><span>{resumeData?.resumeTitle}</span></div>
            <h1 className="section-title">Work Experience</h1>
            <List 
                listName={"resume-jobs"}
                listItems={jobData.map(jobs)}
                listClass={""}
                />
            <h1 className="section-title">Projects</h1>
                <ul>
                    {projectData.map(x=>(
                        <li>
                            <h4>{x.projectTitle}</h4>
                            <p>{x.projectDesc}</p>
                            </li>
                    ))}
                </ul>
            <h1 className="section-title">Skills</h1>
            <div className="flex-col flex-spaced skills-list">
                <S list={skillsData}/>
            </div>
            </div>
        </div>
    )
    }
}