import { months } from "../data/months";

export const dateMMMYYY=(dateInput,monthStr,yearStr)=>{
    const month = months[dateInput.substring(monthStr[0],monthStr[1])-1];
    const year = dateInput.substring(yearStr[0],yearStr[1]);
    return month + ', ' + year;
}

export const dateHHMM=()=>{
    const output = new Date;
    return (`${output.getHours()}:${output.getMinutes()}`);
}

export const dateHHMMSS=()=>{
    const output = new Date;
    return `${output.getHours()}: ${output.getMinutes()}:${output.getSeconds()}`
}

export const dateCurrTS=()=>{
    const d = new Date();
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
}