import React from "react";
import { XMLPromise, XMLRequest } from "../../../utils/calls";
import { Form } from "../../form/form";
import {useDataContext} from "../../../providers/data/provider_data";
import {createFormObject} from "../../../utils/frmObject";
import { useThemeContext } from "../../../providers/theme/provider_theme";
import { JWTRequest } from "../../../utils/jwt";
import userAuth from "../../../utils/userAuth";

export const ModalCreateAccount=({modalstate})=>{
    const { theme, setTheme } = useThemeContext();    
    const handleSubmit= async (e)=>{
        e.preventDefault();
        const frmLogin=document.getElementById("frm-create-acct");
        const login=new FormData(frmLogin);
        const frmObj=createFormObject(login);
        if (!frmObj.userID || !frmObj.email || !frmObj.password) {
            console.log("Please fill all required fields");
            return
        };
        // console.log(frmObj);
        // const res = await new Promise((resolve, reject)=>{
        //     try {
                // XMLRequest(frmObj,"","POST","/php/testing/submitUser.php");
        //         resolve ("success...");
        //     } catch (err){
        //         reject (err);
        //     };
        // })
        // XMLRequest(frmObj,"","POST","/php/testing/submitUser.php");
        await XMLPromise(frmObj,"","POST","/php/index.php/user/submitUser");
        frmObj.theme=theme;
        await userAuth.setSession(frmObj);
        const jwt = await userAuth.getSession();
        console.log(jwt);
        // .then(data=>console.log(data));
        // console.log(res);
        // modalstate();
    } 

    const handleCancel=(e)=>{
        e.preventDefault();
        modalstate();
    }
    const handleCreateAccount=(e)=>{
        e.preventDefault();
        modalstate();
    }
    return(
        <Form 
        formClasses={``}
        formInputs={[
            
            {default: `Email`, label: "Email", name:"email",type:"text"},
            {default: `Password`, label: "Password", name:"password",type:"text"},
            {default: `UserId`, label:"User ID", name:"userID", type:"text"}
            ]} 
        formButtons={[
            {button:"Submit",function:handleSubmit, class:`btn btn-contrast`},
            {button:"Cancel",function:handleCancel, class:`btn btn-contrast`},
            ]} 
            formName={"frm-create-acct" }
    />
    )
}