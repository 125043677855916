import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useThemeContext } from "../../providers/theme/provider_theme";
import { useModalContext } from "../../providers/modal/provider_modal";
import { useDataContext } from "../../providers/data/provider_data";
import {Form} from "../../components/form/form";
import { XMLPromise, XMLRequest } from "../../utils/calls";
import userAuth from "../../utils/userAuth";
import { Button } from "../../components/button/button";
import { useUserContext } from "../../providers/user/provider_user";
import { List } from "../../components/list/list";
import Validate from "../../utils/validations";
import { Spinner } from "../../components/loading/spinner";
import { createFormObject, createFormObjectTest } from "../../utils/frmObject";
import { ArrayBuilding, arrayGroupBy } from "../../utils/array";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { Text } from "../../components/text/text";
import { handleEdit } from "../../utils/buttonFunctions";
import {ReactComponent as Logo} from "../../images/bee_logo.svg";



export const TestingPage =()=>{
    // const [onScreen1,setOnScreen1]=useState(false);
    // const [onScreen2,setOnScreen2]=useState(false);
    const {state} = useLocation();
    const {paramId}=useParams();
    const [testing, setTesting] = useState();
    const [testList, setTestList] = useState([]);
    const [resumeData, setResumeData] = useState();
    const [submit,setSubmit] = useState();
    const [jobId,setJobId] = useState();
    const [resumeId,setResumeId] = useState();
    const [headshots, setHeadshots] = useState(0);
    const {user, setUser, authorize} = useUserContext();
    const {setModal} = useModalContext();

    const [source, setSource]=useState(
        [
            {key:"s1", class:"standard", element:<p>Source 1</p>},
            {key:"s2", class:"standard", element:<p>Source 2</p>},
            {key:"s3", class:"standard", element:<p>Source 3</p>}
        ]
    );
    const [dest,setDest]=useState([
        {key:"d1", class:"standard", element:<p>Dest 1</p>},
        {key:"d2", class:"standard", element:<p>Dest 2</p>},
        {key:"d3", class:"standard", element:<p>Dest 3</p>}
    ]);
    const handleOnScreen=(element, value)=>{
        value? element.classList.add("visible"): element.classList.remove("visible");
    };

    const a = async(b)=>{
        if(!Validate.exists(b)){
            // window.location.replace("/");
        }
    }

    const handleTestSubmit= async ()=>{
        // e.preventDefault();
        // const frmObj = {resumeId,jobId}
        // setTesting(e.target.value);
        const frmObj={resumeId:paramId};
        // const frmObj = createFormObjectTest("link-frm");
        console.log(frmObj);
        const frmData = await XMLPromise(frmObj,"","POST","/php/index.php/resume/listJobs/");
        // const compArray= ArrayBuilding(frmData.data.jobs);
        const compArray = arrayGroupBy(frmData.data.jobs,"jobsCompany");
        setTestList(compArray);
        setResumeData(frmData.data.resume[0]);
        console.log(frmData);
        // console.log(Object.keys(compArray));
        
    }
    useEffect(()=>{
        
    },[])

    const handleLogOut=async()=>{
        const auth = await XMLPromise("","","GET","/php/index/auth/logOut/");
        setUser(auth);
    }

    const handleProfile= async()=>{
        const vals ={userId:"swsosebee2", password:"testing"};
        const auth = await XMLPromise(vals,"","POST","/php/index/user/getProfile/");
        setTesting(JSON.stringify(auth));
    }

    const submitDest=(v)=>{
        setTesting(v);
    }

    const submitSource=(v)=>{
        console.log(v);
    }
    const selection=(v)=>{
        setJobId(v);
        // setSubmit(v);
    }
    const selectionD=(v)=>{
        setResumeId(v);
        // console.log(v)
    }

    

    const algTest=()=>{
        const s = "12:00:00AM";
        const amPm =s.substring(s.length-2);
        const time= s.substring(0,s.length-2);
        const timeArr = time.split(':')

        console.log(parseInt(timeArr[0]));
        if(timeArr[0]=="12"){timeArr[0]="00"};
        if(amPm=="PM"){timeArr[0]=String(parseInt(timeArr[0])+12)};
                
        console.log(`${timeArr.join(':')}`)
        
    }
    const handleTestScript=async(e,model)=>{
        // console.log(model);
        // const id=model+'id';
        // const obj ={[id]:1};
        // obj[id]=1;
        // console.log(obj);
        const inputs = "S;V;iPad"
        const ops = inputs.split(';');
        let output = ops.map(word=>{
            return word;
        })
        console.log(output);
        // const res = await handleEdit(e,"job","getID");
        // console.log(res)
    }

    const getStackOverflow= async ()=>{
        // const data = await XMLPromise("","","GET","https://api.stackexchange.com/2.3/users/19899015?order=desc&sort=reputation&site=stackoverflow");

        fetch("https://api.stackexchange.com/2.3/users/19899015?order=desc&sort=reputation&site=stackoverflow&filter=!BTe4TXzBfpDzBTw6SJSRu)nfaCHr3V")
        .then(res=>res.json())
        .then(data=>console.log(data.items[0]));
    }
    // algTest();

    return(
        <div className="margin-90">
        <div><Outlet/></div>
        <div id="linkTesting">
            <Form 
                formName={"link-frm"}
                formInputs={[
                    {name:"jobId",type:"text", class:"standard"},
                    {name:"resumeId",type:"text", class:"standard"},
                ]}
                formButtons={[
                    {button:"Submit",function:handleTestSubmit,class:"btn btn-contrast"}
                    // <Button styleClass={"btn btn-contrast"} buttonText={"Submit"} handleClick={(e)=>{e.preventDefault();console.log("submitted")}}/>
                ]}/>
        </div>
        <ul className="list-plain">
            <li className="dropdown-container">Testing
                <ul className="list-plain dropdown">
                    <li>Option1</li>
                    <li>Option2</li>
                    <li>Option3</li>
                </ul>
            </li>
        </ul>        
            
            <Button  buttonText={"useEffect Test"} handleClick={handleTestSubmit} styleClass={"btn btn-contrast"}/>
        <Button  buttonId={77} buttonText={"Testing"} handleClick={(e)=>handleTestScript(e,"test")} styleClass={"btn btn-contrast"}/>
        <Button buttonText={"Get StackOverflow"} handleClick={getStackOverflow} styleClass={"btn btn-contrast"}/>
        <input style={{backgroundColor:"black"}} id="testSubmit"></input>
        
        <div>
            {resumeData?(
            <><h1>{resumeData.resumeTitle}</h1>
                <p>{resumeData.resumeObjective}</p></>): <></>}
        <ul>{Object.keys(testList).map((x,i)=>{
            return (<><h1>{x}</h1>
                        <ul>
                            {testList[x].map(x=>{
                                return <li>{x.jobsName}</li>
                        })}</ul>
            </>
            )
                    
        })}</ul>
        </div>
        <div className={'flex-box'}>
            <List 
                returnSelection={selection} 
                returnList={submitSource} 
                missingNote={"not an array"} 
                listName={"source"}  
                listClass={"flex-1 list-plain effect-darken"} 
                listDrag={true} 
                listDrop={true} 
                listItems={source}/>
            <div className="flex-1">
                <List multiSelect={true} returnSelection={selectionD} returnList={submitDest} missingNote={"not an array"} listName={"dest"}  listClass={"list-plain effect-darken"} listDrag={true} listDrop={true} listItems={dest}/>
                <Button styleClass={"btn btn-contrast"} buttonText={"Submit"} handleClick={()=>{console.log({[submit]:testing})}}/>
            </div>
        </div>
        {/* <div className="parallax">
        <h2 id="1" className={`scrolled bright`}>

        </h2>
        </div> */}
        {/* <div style={{height:200, backgroundColor:"transparent"}}></div> */}
        {/* <div className="parallax">
            <div className="blanks"/>
        <h2 id="2" className="scrolled med">

        </h2>
        </div> */}
        </div>
    )
}