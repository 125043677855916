export const createFormObject =(form)=>{
    let createFormObject={};
    for(var pair of form.entries()){
        const obj = pair[0]+ ': '+ pair[1];
        createFormObject[pair[0]]=pair[1];
    };
    return createFormObject;
}

export const createFormObjectTest=(form)=>{
    const frm=document.getElementById(form);
    const formData=new FormData(frm);
    let createFormObject={};
    for(var pair of formData.entries()){
        const obj = pair[0]+ ': '+ pair[1];
        createFormObject[pair[0]]=pair[1];
    };
    return createFormObject;

}