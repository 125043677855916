import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useModalContext } from "../../providers/modal/provider_modal";
import { useDataContext } from "../../providers/data/provider_data";
import { XMLPromise, XMLRequest } from "../../utils/calls";
import { List } from "../../components/list/list";
import { arrayGroupBy, arraySortData } from "../../utils/array";
import { useParams } from "react-router-dom";
import "./resume.css";
import { dateMMMYYY } from "../../utils/formatting";
import { projects } from "../../data/projects";

export const ResumePage=()=>{
    const [resumeData, setResumeData]=useState();
    const [jobData,setJobData]=useState();
    const [skillsData,setSkillsData] = useState();
    const [projectData,setProjectData] = useState();
    const [volunteerData,setVolunteerData] = useState();
    const {resumeId}=useParams();
    
    const initialDownload= async()=>{
        const data = await XMLPromise({resumeId},"","POST","/php/index.php/resume/listJobs/");
        setResumeData(data.data.resume[0]);
        setJobData(data.data.jobs);
        setProjectData(data.data.projects);
    }

    const accomplishments=(x)=>{
        return (
            <li 
                id={x.accId}
                className="effect-darken ">
            <span>{x.accDesc}</span>
        </li>)
    }
    const jobs=(x)=>{
        return ({
                element: <>
                    <p className="job-title">{x.jobsName} at {x.jobsCompany} - {dateMMMYYY(x.jobsStart,[5,7],[0,4])} to {dateMMMYYY(x.jobsEnd,[5,7],[0,4])}</p>
                    <p>{x.jobsDesc}</p> 
                    <ul className="accomplishments">{x.accomplishments.map(a=>(
                        accomplishments(a)))}    
                    </ul>
                    </>,
                key:x.jobsId,
                class:['standard jobs']
            })
    }
    
    const S=({list})=>{
        console.log("skills list...");
        return Object.entries(list).map(([skill,index])=>{
            return <div className="flex-1"><h5>{skill}</h5><ul>
                {list[skill].map(x=>{return <li>{x.skillsName}</li>})
        }</ul></div>})
    }
    useEffect(()=>{
        XMLPromise({resumeId},"","POST","/php/index.php/resume/listJobs/")
            .then(res=>{
                console.log(res);
                setResumeData(res.data.resume[0]);
                setJobData(res.data.jobs);
                setProjectData(res.data.projects);
                setVolunteerData(res.data.volunteer);
                setSkillsData(arrayGroupBy(res.data.skills,"skillsType"));
                console.log(res.data);
            })
    },[])
    
    if(!resumeData || !jobData || !skillsData || !projectData){
        return <div>Loading...</div>
    } else {
    return (
        <div id="resume">
            <div id="cover-letter">
                <h2>Steven Sosebee</h2>
                <div>
                    <ul>
                    <li><p>steven@stevenwsosebee.com</p></li>
                    <li><p>910-850-5836</p></li>
                    <br/>
                    <li><h4>{resumeData?.resumeObjective}</h4></li>
                    </ul>
                </div>
                <p>
                    
                </p>
            </div>
            <div id="cv">
            <div id="resume-title"><span>{resumeData?.resumeTitle}</span></div>
            <h1 className="section-title">Work Experience</h1>
            <List 
                listName={"resume-jobs"}
                listItems={jobData.map(jobs)}
                listClass={""}
                />
            <h1 className="section-title">Projects</h1>
                <ul>
                    {projectData.map(x=>(
                        <li>
                            <p>{x.projTitle}</p>
                            </li>
                    ))}
                </ul>
            <h1 className="section-title">Skills</h1>
            <div className="flex-col flex-spaced skills-list">
                <S list={skillsData}/>
            </div>
            </div>
        </div>
    )
    }
}