import React from "react";
import { XMLPromise, XMLRequest } from "../../../utils/calls";
import { Form } from "../../form/form";
import {useDataContext} from "../../../providers/data/provider_data";
import {createFormObject} from "../../../utils/frmObject";

export const ModalEditor=({modalstate})=>{
    const {data,setData}=useDataContext();

    const handleSubmit=(e)=>{
        e.preventDefault();
        const jobFrm=document.getElementById("jobEdit");
        const jobForm=new FormData(jobFrm);
        const frmObj=createFormObject(jobForm);
        frmObj.jobId=data[0].jobsId;
        console.log(frmObj);
        // XMLRequest(frmObj,setData,"POST","/php/jobsUpdate.php");
        XMLPromise(frmObj,"","POST","/php/index.php/jobs/edit/");
        setData("");
        modalstate();
    }

    const handleCancel=(e)=>{
        e.preventDefault();
        modalstate();
    }
    // console.log(data);
    return(
        <Form formInputs={[
            {default:data[0].jobsCompany,name:"company",type:"text"},
            {default:data[0].jobsName,name:"job",type:"text"}, 
            {default:data[0].jobsDesc,name:"description",type:"text"},
            {default:data[0].jobsStart,label:"Start Date",name:"start-date",type:"date"},
            {default:data[0].jobsEnd,label:"End Date",name:"end-date",type:"date"}]} 
        formButtons={[
            {button:`Edit ${data[0].jobsName}`,function:handleSubmit},
            {button:`Cancel`,function:handleCancel}
            ]} 
            formName={"jobEdit"}/>
    )
}