import React from "react";
import { XMLPromise, XMLRequest } from "../../../utils/calls";
import { Form } from "../../form/form";
import {useDataContext} from "../../../providers/data/provider_data";
import {createFormObject} from "../../../utils/frmObject";
import { useThemeContext } from "../../../providers/theme/provider_theme";
import { useModalContext } from "../../../providers/modal/provider_modal";
import { useUserContext } from "../../../providers/user/provider_user";

export const ModalLogin=({modalstate})=>{
    const {modal, setModal} = useModalContext();    
    const {user, setUser} = useUserContext();

    const handleSubmit= async (e)=>{
        e.preventDefault();
        const frmLogin=document.getElementById("frm-login");
        const login=new FormData(frmLogin);
        const frmObj=createFormObject(login);
        console.log(frmObj);
        // const auth = await XMLPromise(frmObj,"","POST","/php/index/user/getProfile/");
        // console.log(auth);
        const auth = await XMLPromise(frmObj,"","POST","/php/index.php/auth/logIn/");
        setUser(auth);
        // XMLRequest(frmObj,setData,"POST","/php/testing/skillsUpdate.php")
        modalstate();
    } 

    const handleCancel=(e)=>{
        e.preventDefault();
        modalstate();
    }
    const handleCreateAccount=(e)=>{
        e.preventDefault();
        setModal("createAccount");
    }
    return(
        <Form 
        formClasses={``}
        formInputs={[
            
            {placeholder: `Email`, label: "Email", name:"userId",type:"text"},
            {placeholder: `Password`, label: "Password", name:"password",type:"text"}
            ]} 
        formButtons={[
            {button:"Submit",function:handleSubmit, class:`btn btn-contrast`},
            {button:"Cancel",function:handleCancel, class:`btn btn-contrast`},
            {button: "Create Account",function:handleCreateAccount, class:`btn btn-focus`}
            // {button:"Testing",function:Testing}
            ]} 
            formName={"frm-login"}
    />
    )
}