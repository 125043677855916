export const ArrayBuilding =(obj)=>{
    console.log(obj);
    console.log(Object.keys(obj[0]))
    const ObjArray = Object.keys(obj).map(o=>{
        // return obj;
        // console.log(obj);
        console.log(obj[o]);
        // console.log(o);
    });
    // console.log(ObjArray);
}

export const arrayGroupBy=(array,key)=>{
    if(!Array.isArray(array)) return;
    return array.reduce((reducedArray,item)=>{
        (reducedArray[item[key]] = reducedArray[item[key]] || []).push(item);
        return reducedArray;
    },{});
};

export const arraySortData=(array,sortBy,order="down")=>{
    // console.log(values);
    if (order==="up"){
    return array.sort((a,b)=>{
        const startA = a[sortBy];
        const startB = b[sortBy];
        if(startA<startB) return -1;
        if(startA>startB) return 1;
        return 0;
    })} else {
        return array.sort((a,b)=>{
            const startA = a[sortBy];
            const startB = b[sortBy];
            if(startA>startB) return -1;
            if(startA<startB) return 1;
            return 0;
    })}
    ;
};