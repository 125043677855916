import decode from 'jwt-decode';
import { XMLPromise } from './calls';

class UserAuth {
    
    setJWT(){
            console.log("set JWT...")
        const JWT = new XMLHttpRequest();
        JWT.open("GET","/php/testing/session.php",true);
        JWT.withCredentials=true;
        JWT.send();
        JWT.onload =function(){
            if (this.status===200){
                console.log(this.responseText);
                console.log(decode(this.responseText));
            } else {
                return console.log("something went wrong...")
            }
        }
    }

    // decodeJWT(){
    //     console.log("decode JWT...");
    //     const req = this.getSession();

    // }

    async getSession(){
        return await XMLPromise("","","GET","/php/index/auth/getSession/");
    }

    // getSession(){
    //     // console.log("get Session...")
    //     return new Promise ((resolve,reject)=>{
    //     const JWT = new XMLHttpRequest();
    //     JWT.open("GET","/php/session/session.php",true);
    //     JWT.withCredentials=true;
    //     JWT.onload =function(){
    //         try{
    //         if (this.status===200 && this.responseText==!null){
    //             // console.log(this.responseText);
    //             // console.log(decode(this.responseText));
    //             // resolve( console.log("success..."));
    //             // resolve( console.log({data:decode(this.responseText), call:JWT}));
    //                 resolve( {data:decode(this.responseText), call:JWT});
    //         } else {
    //             // console.log(this.responseText);
    //             // return decode(this.responseText);
    //             reject( {data:"something went wrong..."});
    //         }
    //     } catch (err){
    //         console.log(err);
    //     }
    //     }
    //     JWT.send();
    // })
    // }

    // setSession(payload){
    //     // console.log("get Session...")
    //     return new Promise ((resolve,reject)=>{
    //     const JWT = new XMLHttpRequest();
    //     JWT.open("POST","/php/testing/session.php",true);
    //     JWT.withCredentials=true;
    //     JWT.onload =function(){
    //         if (this.status===200){
    //             // console.log(this.responseText);
    //             // console.log(decode(this.responseText));
    //             // resolve( console.log("success..."));
    //             // resolve( console.log({data:decode(this.responseText), call:JWT}));
    //             resolve( {data:decode(this.responseText), call:JWT});
    //         } else {
    //             // console.log(this.responseText);
    //             // return decode(this.responseText);
    //             reject( console.log("something went wrong..."));
    //         }
    //     }
    //     JWT.send(JSON.stringify(payload));
    // })
    // }

    // logIn(){
    //     window.location.replace("/");
    // }
    // async logOut(){
    //     const req = this.getSession();
    //     req.then(data=>{console.log(data.data);return data});
    // }
    

}

export default new UserAuth();