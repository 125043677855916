import "./App.css";
// react methods and objects
import React, { useState, useContext, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// routing and API calls
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import Auth from './utils/auth';
// contexts
import {
  ThemeContext,
  ThemeProvider,
  useThemeContext,
} from "./providers/theme/provider_theme";
import {
  ModalContext,
  ModalProvider,
  useModalContext,
} from "./providers/modal/provider_modal";
import {
  HostContext,
  HostProvider,
  useHostContext
} from "./providers/host/provider_host";
import {
  DataContext,
  DataProvider,
  useDataContext
} from "./providers/data/provider_data";
import {
  UserContext,
  UserProvider,
  useUserContext
} from "./providers/user/provider_user";
import { DnDProvider } from "./providers/dragging/drag-n-drop";
import { AuthorizationProvider } from "./providers/authorization/provider_authorization";
// components and pages
import { Modal } from "./components/modal/modal";
import { List } from "./components/list/list";
import { Sidebar } from "./components/sidebar/sidebar";
import { Footer } from "./components/footer/footer";
import { Headers } from "./components/header/header";
import { Button } from "./components/button/button";

// style sheets
import "./styles/variables.css";
import "./styles/utility-classes.css"
import "./styles/buttons.css";
import "./styles/lists.css";
// import "./styles/animations";
import "./styles/text.css";
// import "./styles/animations/ani.css";


import { ResumeBuilder } from "./pages/resume_builder/resumev2";
import { HomePage } from "./pages/home/home";
import { LoginPage } from "./pages/utilities/login";
import { SkillsBuilder } from "./pages/skills_builder/skills_builder";
import { ContentSection } from "./components/content/content";
import {TestingPage} from "./pages/testing/testingPage";
import { BabyPage } from "./pages/baby/baby";
import { Resume } from "./pages/resume_builder/resume";
import Page404 from "./pages/404";
// import { JWTRequest } from "./utils/jwt";
import userAuth from "./utils/userAuth";
import { Text } from "./components/text/text";
import { TextCopy } from "./components/text/text copy";
import { ResumePage } from "./pages/resume/resumePage";
import { Accomplishments } from "./pages/resume_builder/accomplishments/accomplishmentsSecion";
import { JobBuilder } from "./pages/resume_builder/jobBuilder";
import { Skills } from "./pages/resume_builder/skills/skillsBuilder";
import { SkillResume } from "./pages/resume_builder/skills/skillResume";
import { ProjectsBuilder } from "./pages/resume_builder/projects/projectsBuilder";
import { ProjectResume } from "./pages/resume_builder/projects/projectResume";
import { ServicesPage } from "./pages/services/servicesPage";
import { StaticResume } from "./pages/staticResume/staticResume";



// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('id_token');
  const token = userAuth.getSession();
  console.log(token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  link: authLink,
  cache: new InMemoryCache(),
});

// console.log(<AuthorizationProvider/>)
function App() {

  return (
    // <ApolloProvider client={client}>
      <HostProvider>
        {/* <UserProvider> */}
          <ThemeProvider>
            <DnDProvider>
              <DataProvider>
                <ModalProvider>
                
              <Modal />
              
              {/* <Sidebar /> */}
              
              <Router>
                {/* <Header2/> */}
                <Headers/>
              <ContentSection>
                  <Routes>
                    <Route exact path="/" element={<HomePage/>}/>
                    {/* <Route path="/builder" element={loggedIn? <Resume/>: <HomePage/>}/> */}
                    {/* <Route path="/login" element={<LoginPage/>}/> */}
                    {/* <Route path="/builder" element={<AuthorizationProvider><Resume/></AuthorizationProvider>}/> */}
                    {/* <Route path="/skills" element={<SkillsBuilder/>}/> */}
                    {/* <Route path="/testing" element={<AuthorizationProvider><TestingPage/></AuthorizationProvider>}/> */}
                    {/* <Route path="/baby" element={<BabyPage/>}/> */}
                    <Route path="/resume/:resumeId" element={<ResumePage/>}/>
                    {/* <Route path="/resumebuilder" element={<ResumeBuilder/>}/> */}
                    {/* <Route path="/accomplishments" element={<Accomplishments/>}/> */}
                    {/* <Route path="/projectbuilder" element={<ProjectsBuilder/>}/> */}
                    {/* <Route path="/project/add/" element={<ProjectResume/>}/> */}
                    {/* <Route path="/skills" element={<Skills/>}/> */}
                    {/* <Route path="/skills/add/" element={<SkillResume/>}/> */}
                    {/* <Route path="/jobBuilder" element={<JobBuilder/>}/> */}
                    <Route path="/resume" element={<StaticResume/>}/>
                    <Route path="/resumes" element={<TestingPage/>}>
                      <Route path=":paramId" element={<Text/>}/>
                    </Route>
                    <Route path="/services" element={<ServicesPage/>}/>
                    <Route path="*" element={<Page404/>}/>
                  </Routes>
                  </ContentSection>
                </Router>
                
                
              <Footer />
                  
                </ModalProvider>
              </DataProvider>
            </DnDProvider>
          </ThemeProvider>
        {/* </UserProvider> */}
      </HostProvider>
    // </ApolloProvider>
  );
}

export default App;
