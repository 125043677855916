import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useModalContext } from "../../providers/modal/provider_modal";
import { useThemeContext } from "../../providers/theme/provider_theme";
import { useUserContext } from "../../providers/user/provider_user";
import { Button } from "../button/button";
import { List } from "../list/list";
import { Toggle } from "../toggle/toggle";
import {ReactComponent as Logo} from "../../images/bee_logo.svg";
import "./header.css";
import { XMLPromise, XMLRequest } from "../../utils/calls";

export const Headers = () => {
  const { theme, setTheme } = useThemeContext();
  const {modal, setModal} = useModalContext();
  // const {user, setUser, logOut} = useUserContext();
  const [resumes,setResumes] = useState();

  // const [activeMenuItem, setActiveMenuItem] = useState();
  const [value, setValue] = useState(true);
  // const handleActiveMenu = (e) => {
  //   const active = e._targetInst.return.key;
  //   setActiveMenuItem(active);
  // };

  // useEffect(()=>{
  //   XMLPromise("","","GET","/php/index.php/resume/list/")
  //     .then(res=>setResumes(res.data));
  // },[]);

  const handleToggleTheme = () => {
    if(!value === true) {
        setTheme("dark"); 
      }
      else {
        setTheme("light");
        }
    setValue(!value);
  };

  // const handleLogOut=async()=>{
  //   logOut();
    
  // };

  // let resumeNav = !resumes? <></>:
  //   (
  //     <div className="dropdown-container">
  //       <Button styleClass={`btn`} buttonText={`Resumes`}/>
  //       <ul className="dropdown left">
  //       {resumes.map(resume=>(
  //         <a href={`/resume/${resume.id}`}><li><Button styleClass={`btn`} buttonText={`${resume.resumeTitle}`}/></li></a>  
  //       ))}
  //       {/* <a href="/resume/3"><li><Button styleClass={`btn`} buttonText={`Operations Manager`}/></li></a> */}
  //       </ul>
  //     </div>
  //   )
  let headerLinks= false?
  (<>
      <li><a href="/builder"><Button styleClass={`btn`} buttonText={`Resume`}/></a></li>
      <li><a href="/"><Button styleClass={`btn`} buttonText={`Home`}/></a></li>
      <li>
        <div className="dropdown-container"><Button styleClass={`btn`} buttonText={`Add Data`}/>
          <ul className="dropdown left">
            <li><a href="/skills"><Button styleClass={`btn`} buttonText={`Skills`}/></a></li>
            <li><a href="/accomplishments"><Button styleClass={`btn`} buttonText={`Accomplishments`}/></a></li>
            <li><a href="/projectbuilder"><Button styleClass={`btn`} buttonText={`Projects`}/></a></li>
            <li><a href="/jobbuilder"><Button styleClass={`btn`} buttonText={`Jobs`}/></a></li>
          </ul>
        </div>
      </li>
      <li>
        <div className="dropdown-container">
          <a href="/skills"><Button styleClass={`btn`} buttonText={`Link Resume Data`}/></a>
          <ul className="dropdown left">
            <li><a href="/skills/add"><Button styleClass={`btn`} buttonText={`Add Skills`}/></a ></li>
            <li><a href="/project/add"><Button styleClass={`btn`} buttonText={`Add Projects`}/></a ></li>
          </ul>
        </div>
      </li>
      <li><a href="/testing"><Button styleClass={`btn`} buttonText={`Testing`}/></a></li>
      {/* <li><Button styleClass={`btn`} buttonText={`Log Out`} handleClick={handleLogOut}/></li> */}
  </>):
  (
      <>
        {/* <li>
          <a href="/login"><Button styleClass={`btn`} buttonText={`Login`}/></a>
        </li> */}
      </>
  )
  return (
    <header className={`lighter-1 ${theme}`}>
      {/* <img id={`header-icon`} src={require("../../images/profile.jpg")}/> */}
      <div><Logo className="logo"/>
        {/* <div>{user?.session?.userId? `Welcome, ${user?.session?.userId}`:"" }</div> */}
      </div>
      <div id={`header-main`}>
        {/* <h3 className="head">Steven Sosebee</h3> */}
        <ul id="nav">
          {/* <li>{resumeNav}</li> */}
          <li><a href="/"><Button styleClass={`btn`} buttonText={`Home`}/></a></li>
          <li><a href="/resume"><Button styleClass={`btn`} buttonText={`Resume`}/></a></li>
          {/* <li><a href="/services"><Button styleClass={`btn`} buttonText={`Services`}/></a></li> */}
          {headerLinks}
        </ul>   
      </div>
      {/* <div id="header-controls">
          <span>Dark Mode:</span>
          <Toggle
          isOn={value}
          handleToggle={() => handleToggleTheme()}/>
        </div> */}
    </header>
  );
};
